/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api487df85d",
            "endpoint": "https://4vsmxjva5j.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:c0408c4b-de6c-48ff-a7ed-d858cca01674",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RxWhb740h",
    "aws_user_pools_web_client_id": "6m9mc3tp37gvu5rjveb2chplol",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "antropomediatools-staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
